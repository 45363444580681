import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// components
import Sidebar from 'components/common/Sidebar/Sidebar';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views
import WorkList from 'views/work/WorkList';
import WorkEdit from 'views/work/WorkEdit';
import Colleague from 'views/work/Colleague';
import CheckIn from 'views/work/checkIn';
import TimeStamp from 'views/work/timeStamp';
import TimeStampSetting from 'views/work/TimeStampSetting';
import Loading from 'components/Loading';
import * as actions from '../redux/action';
import Calendar from '../views/work/calendar';
import TimeStampCheckOut from '../views/work/TimeStampCheckOut';
import CalendaMe from '../views/work/CalendaMe';
import Leave from '../views/work/Leave';
import Confirmleave from '../views/work/ConfirmLeave';
import NewTimeStampSetting from 'views/work/NewTimeStampSetting';
import EditTimeStampSetting from 'views/work/EditTimeStampSetting';
import ApplyLeave from '../views/work/ApplyLeave';
import LeaveDocApply from 'views/work/LeaveDocApply';
import { HomeNavbar } from 'components/Navbars';

// import { HomeNavbar } from 'components/Navbars';

export default function Work() {
  const name = 'การทำงาน';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [setMobileNavOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());

    return () => {};
  }, []);
  if (me.isLoading) {
    return <Loading isLoading />;
  }
  if (!me.isLoading) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar setMobileNavOpen={setMobileNavOpen} />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          {/* Header */}

          <div className="py-4 w-full min-h-screen   ">
            <Switch>
              <Route path="/work/calendar" exact>
                <Calendar title="ตารางงาน" subtitle={name} />
              </Route>
              <Route path="/work/calendar-me" exact>
                <CalendaMe title="ตารางงาน" subtitle={name} />
              </Route>
              <Route path="/work/list" exact>
                <WorkList title="รายการงาน" subtitle={name} />
              </Route>
              <Route path="/work/edit/:id" exact>
                <WorkEdit title="แก้ไขงาน" subtitle={name} />
              </Route>
              <Route path="/work/colleague" exact>
                <Colleague title="ติดต่อพนักงาน" subtitle={name} />
              </Route>
              <Route path="/work/checkIn/out/:id" exact>
                <TimeStampCheckOut title="ออกงาน" subtitle={name} />
              </Route>
              <Route path="/work/setting/timestamp-setting" exact>
                <TimeStampSetting title="สถานที่ทำงาน" subtitle={name} />
              </Route>

              <Route path="/work/setting/timestamp-setting/new" exact>
                <NewTimeStampSetting
                  title="เพิ่มสถานที่ทำงาน"
                  subtitle={name}
                />
              </Route>
              <Route path="/work/setting/timestamp-setting/edit/:id" exact>
                <EditTimeStampSetting
                  title="แก้ไขสถานที่ทำงาน"
                  subtitle={name}
                />
              </Route>
              <Route path="/work/checkIn" exact>
                <CheckIn title="รายการเข้างาน" subtitle={name} />
              </Route>
              <Route path="/work/checkIn/add/" exact>
                <TimeStamp title="เข้างาน" subtitle={name} />
              </Route>
              <Route path="/work/leave-work" exact>
                <Leave title="ลางาน" subtitle={name} />
              </Route>
              <Route path="/work/leave-work/aprove/:id" exact>
                <LeaveDocApply title="ลางาน" subtitle={name} />
              </Route>
              <Route path="/work/leave-work/confirm" exact>
                <Confirmleave title="รายชื่อการลา" subtitle={name} />
              </Route>
              <Route path="/work/leave-work/apply" exact>
                <ApplyLeave title="ยื่นใบลา" subtitle={name} />
              </Route>

              <Redirect from="/." to="/." />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}
