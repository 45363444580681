/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useParams, useHistory } from 'react-router';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { BackButton } from 'components/Button';
import { ViewTitle } from 'components/ViewTitle';
import * as action from '../../redux/action';
import Loading from 'components/Loading';
import UndefinedLeavedoc from 'components/Card/UndefinedLeavedoc';

function LeaveDocApply({ title, subtitle }) {
  const [note, setNote] = useState('-');
  const { handleSubmit } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const leaveDocs = useSelector((state) => state.leaveDoc);
  const me = useSelector((state) => state.me);
  const settings = useSelector((state) => state.setting);
  const [checkedHalf, setCheckedHalf] = useState(false);
  dayjs.extend(buddhistEra);
  const setting = settings?.arr;

  useEffect(() => {
    dispatch(action.getOneLeaveDoc(params.id));

    return () => {};
  }, [leaveDocs?.name]);

  useEffect(() => () => {}, [leaveDocs, leaveDocs?.status]);
  // const sumDay = () => {
  //   const date1 = dayjs(leaveDocs?.dateEnd).startOf('day');
  //   const date2 = dayjs(leaveDocs?.dateStart).startOf('day');
  //   const date = date1.toDate().getTime() - date2.toDate().getTime();
  //   const sumday = date / (1000 * 3600 * 24);

  //   return sumday + 1;
  // };

  const sumDay = () => {
    const date1 = dayjs(leaveDocs?.dateEnd).startOf('day');
    const date2 = dayjs(leaveDocs?.dateStart).startOf('day');

    // คำนวณความแตกต่างในวัน
    const dateDiff = date1.diff(date2, 'day') + 1;
    const dateHalf = date1.diff(date2, 'day') + 0.5;

    console.log(dateHalf);

    // ปรับผลลัพธ์ตามเงื่อนไข
    let totalDays = dateDiff;
    let totalDayHalf = dateHalf;

    if (checkedHalf === true) {
      return totalDayHalf;
    } else {
      return totalDays;
    }
  };

  const renderBackButton = () => <BackButton />;

  const onApproveButton = async () => {
    try {
      const confirm = window.confirm('ยืนยันอนุมัติใบลา');
      const payload = {
        approve1: {
          note,
          status: 'อนุมัติ',
          approve_by: leaveDocs?.name,
          approve_status: true,
          approve: leaveDocs?._id,
        },
        status: setting?.multipleApplover ? 'รอการพิจารณา' : 'อนุมัติ',
        approve_status: !setting?.multipleApplover,
      };
      if (confirm) {
        await dispatch(action.putLeaveDoc(params.id, payload));
        // await dispatch(
        //   action.getAllLeaveDoc({
        //     approve_status: false,
        //     name: leaveDocs?.name,
        //     approve: leaveDocs?._id,
        //   })
        // );
        history.push(`/work/leave-work/confirm`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onApprove2Button = async () => {
    try {
      const confirm = window.confirm('ยืนยันอนุมัติใบลา');
      if (confirm) {
        await dispatch(
          action.putLeaveDoc(params.id, {
            approve2: {
              note,
              status: 'อนุมัติ',
              approve_by: leaveDocs?.name,
              approve_status: true,
              approve: leaveDocs?._id,
            },
            status: 'อนุมัติ',
            approve_status: true,
          })
        );
        history.push(`/work/leave-work/confirm`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRejectButton = async () => {
    const confirm = window.confirm('ยืนยันไม่อนุมัติใบลา');
    if (confirm) {
      await dispatch(
        action.putLeaveDoc(params.id, {
          approve1: {
            note,
            status: 'ไม่อนุมัติ',
            approver_by: leaveDocs?.name,
            approve_status: true,
            approve: leaveDocs?._id,
          },
          status: 'ไม่อนุมัติ',
          approve_status: true,
        })
      );
      await dispatch(action.getOneLeaveDoc(params.id));
    }
  };
  const onReject2Button = async () => {
    const confirm = window.confirm('ยืนยันไม่อนุมัติใบลา');
    if (confirm) {
      await dispatch(
        action.putLeaveDoc(params.id, {
          approve2: {
            note,
            status: 'ไม่อนุมัติ',
            approve_by: leaveDocs?._id,
            approve_status: true,
            approve: leaveDocs?._id,
          },
          status: 'ไม่อนุมัติ',
          approve_status: true,
        })
      );
      await dispatch(action.getOneLeaveDoc(params.id));
    }
  };

  const onCancelButton = async () => {
    const confirm = window.confirm('ยืนยันยกเลิกใบลา');
    if (confirm) {
      await dispatch(
        action.putLeaveDoc(params.id, {
          note,
          status: 'ยกเลิก',
          approver_by: leaveDocs?._id,
          approve_status: true,
        })
      );
      await dispatch(action.getOneLeaveDoc(params.id));
    }
  };

  const onchangeNote = (e) => {
    setNote(e.target.value);
  };
  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  // const dataLeave = _.findIndex(leaveDocs?.arr,)

  if (
    leaveDocs?.isCompleted &&
    !leaveDocs?.isLoading &&
    settings?.isCompleted &&
    !settings?.isLoading
  ) {
    if (!leaveDocs?._id) {
      return (
        <div>
          <UndefinedLeavedoc />
        </div>
      );
    }
    return (
      <div className="min-h-screen">
        {renderViewTitle()}
        <form onSubmit={handleSubmit()}>
          <div className="pb-2">{renderBackButton()}</div>
          <div className=" ">
            <div>
              <Card>
                <CardContent>
                  <div className="p-2">
                    <div className="text-xl font-bold py-2 ">ข้อมูลพนักงาน</div>
                    <div className="grid grid-cols-2 gap-1">
                      <div>
                        ชื่อ-สกุล : {leaveDocs?.name} {leaveDocs?.surname}{' '}
                      </div>
                      <div>รหัสพนักงาน : {leaveDocs?.employeeId}</div>
                      <div>
                        สังกัด/บริษัทย่อย :{' '}
                        {leaveDocs?.subBusiness?.name || '-'}
                      </div>
                      <div>แผนก : {leaveDocs?.department?.name || '-'}</div>
                    </div>
                  </div>

                  <div className="p-2">
                    <div className="text-xl font-bold py-2 ">รายละเอียด</div>
                    <div className="grid grid-cols-2 gap-1">
                      <div>ประเภทการลา : {leaveDocs?.leaveTypeID?.name}</div>
                      <div>สาเหตุ : {leaveDocs?.leaveCause || '-'}</div>
                      <div>
                        วันที่เริ่มลา :{' '}
                        {dayjs(leaveDocs?.dateStart).format('DD/MM/BB')}
                      </div>
                      <div>
                        วันสิ้นสุดการลา :{' '}
                        {dayjs(leaveDocs?.dateEnd).format('DD/MM/BB')}
                      </div>
                      <div>จำนวน : {sumDay()} วัน</div>
                      <div>
                        ช่องทางติดต่อระหว่างการลา : {leaveDocs?.contact || '-'}
                      </div>
                    </div>
                    <div className="text-l  py-2 ">เอกสารการลาเพิ่มเติม</div>
                    <div>
                      <a href={leaveDocs?.file?.url} target="_blank">
                        <div className="text-blue-600 font-bold">
                          {leaveDocs?.file?.file_name}
                        </div>
                      </a>
                    </div>
                  </div>
                  {console.log(me)}
                  <div className="pt-8   ">
                    <div className="">
                      <div className="flex w-full gap-2 ">
                        {leaveDocs?.approver_by?._id === me?.userData?._id && (
                          <div className="w-full">
                            <h1 className=" font-bold ">ผู้พิจารณา </h1>
                            <h1 className="text-l py-2 ">
                              {leaveDocs?.approver_by?.name}
                              {leaveDocs?.approver_by?.surname}
                            </h1>
                            <div className="font-bold">ผลการพิจารณา</div>
                            <div className="">
                              {leaveDocs?.approve1?.status}
                            </div>
                            {/* {leaveDocs?.approve1?.approve_status && (
                          <div className="py-2">
                            <Button
                              color="warning"
                              variant="outlined"
                              size="small"
                              onClick={() => onCancelButton()}
                            >
                              ยกเลิก
                            </Button>
                          </div>
                        )} */}
                            {!leaveDocs?.approve1?.approve_status && (
                              <div>
                                {' '}
                                <h1 className="font-bold  pt-4 ">หมายเหตุ</h1>
                                <div className="text-l pt-2">
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    onChange={(e) => {
                                      onchangeNote(e);
                                    }}
                                    label="หมายเหตุ"
                                    defaultValue={leaveDocs?.note}
                                    variant="outlined"
                                    rows={4}
                                  />
                                </div>
                                <div className="pt-6">
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={() => {
                                        onApproveButton();
                                      }}
                                    >
                                      อนุมัติ
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => {
                                        onRejectButton();
                                      }}
                                    >
                                      ไม่อนุมัติ
                                    </Button>
                                  </Stack>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {leaveDocs?.approver2_by?._id === me?.userData?._id && (
                          <div className=" w-full ">
                            <h1 className=" font-bold ">ผู้พิจารณาคนที่ 2 </h1>
                            <h1 className="text-l py-2 ">
                              {leaveDocs?.approver2_by?.name}{' '}
                              {leaveDocs?.approver2_by?.surname}
                            </h1>
                            <div className="font-bold">ผลการพิจารณา</div>
                            <div className="font-bold">
                              {leaveDocs?.approve2?.status}
                            </div>
                            {leaveDocs?.approve2?.approve_status && (
                              <div className="py-2">
                                <Button
                                  color="warning"
                                  variant="outlined"
                                  size="small"
                                  onClick={() => onCancelButton()}
                                >
                                  ยกเลิก
                                </Button>
                              </div>
                            )}
                            {!leaveDocs?.approve2?.approve_status && (
                              <div>
                                {' '}
                                <h1 className="font-bold  pt-4 ">หมายเหตุ</h1>
                                <div className="text-l pt-2">
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    onChange={(e) => {
                                      onchangeNote(e);
                                    }}
                                    label="หมายเหตุ"
                                    defaultValue={leaveDocs?.note}
                                    variant="outlined"
                                    rows={4}
                                  />
                                </div>
                                <div className="pt-6">
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={() => {
                                        onApprove2Button();
                                      }}
                                    >
                                      อนุมัติ
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => {
                                        onReject2Button();
                                      }}
                                    >
                                      ไม่อนุมัติ
                                    </Button>
                                  </Stack>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (!leaveDocs?.isCompleted || leaveDocs?.isLoading) {
    return <Loading isLoading />;
  }
}
export default LeaveDocApply;
