/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import { DateTime } from 'luxon';
import { Box } from '@mui/system';
import {
  Button,
  Card,
  Modal,
  Stack,
  Switch,
  FormControlLabel,
} from '@mui/material';

import HouseIcon from '@mui/icons-material/House';

import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Add from '@mui/icons-material/Add';
import _ from 'lodash';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import axios from 'axios';
import * as action from '../../redux/action';
import AddWork from '../../components/Forms/AddWork';
import JobInfo from '../../components/Modal/JobInfo';

export default function Calendar({ title, subtitle }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const work = useSelector((state) => state.work);
  const me = useSelector((state) => state.me);
  const leaveDoc = useSelector((state) => state.leaveDoc);
  const { control, setValue, handleSubmit, reset } = useForm({
    mode: 'onChange',
  });
  const [open, setOpen] = React.useState(false);
  const [openJob, setOpenJob] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState();
  const [job, setJob] = React.useState();
  const [fillterMe, setFilterMe] = React.useState('');
  const [workAllDay, setWorkAllday] = React.useState(false);
  const [allDay, setAllDay] = React.useState(false);
  const [halfDay, setHalfDay] = React.useState(false);
  const [dayOff, setDayOff] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [dayOffHalfDay, setDayOffHalfDay] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleJobOpen = () => setOpenJob(true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeArray',
  });
  const defaultValue = {
    job: '',
  };

  useEffect(() => {
    dispatch(action.getAllUser({}));

    dispatch(action.getAllwork({ fillterMe }));
    dispatch(action.getAllLeaveDoc({ name: '', page: 1, size: 0 }));

    return () => {};
  }, [fillterMe]);

  const fillterWork = (e) => {
    if (e.target.checked) {
      return setFilterMe(me?.userData?._id);
    }
    return setFilterMe('');
  };

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSwitch = () => (
    <FormControlLabel
      control={<Switch onChange={(e) => fillterWork(e)} lablel="เฉพาะฉัน" />}
      label="เฉพาะฉัน"
    />
  );

  const onSubmit = async (data) => {
    console.log('data', data);

    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        const statusBoolean = {
          allDay: false,
          workAllDay: false,
          halfDay: false,
          dayOff: false,
          dayOffHalfDay: false,
          laeveWork: false,
          wfh: false,
        };

        switch (data?.status) {
          case 'allDay':
            statusBoolean.allDay = true;
            break;
          case 'workAllDay':
            break;
          case 'halfDay':
            statusBoolean.halfDay = true || leaveDoc?.statusHalfDay;
            break;
          case 'dayOff':
            statusBoolean.dayOff = true;
            break;
          case 'dayOffHalfDay':
            statusBoolean.dayOffHalfDay = true;
            break;
          case 'laeveWork':
            statusBoolean.laeveWork = true;
            break;
          case 'wfh':
            statusBoolean.wfh = true;
            break;
          default:
            break;
        }
        //console.log('leaveDoc?.statusHalfDay', leaveDoc?.statusHalfDay);

        const dataSubmit = {
          ...data,
          date,
          worker: data?.employeeArray,
          name: data?.job,
          creater_id: me?.userData?._id,
          // status: data?.allDay,
          ...statusBoolean,
          allDay: statusBoolean?.allDay,
          halfDay: statusBoolean?.halfDay,
          dayOff: statusBoolean?.dayOff,
          dayOffHalfDay: statusBoolean?.dayOffHalfDay,
          laeveWork: statusBoolean?.laeveWork,
          wfh: statusBoolean?.wfh,
        };

        await dispatch(action.addwork(dataSubmit));
        dispatch(action.getAllUser({}));
        dispatch(action.getAllwork({ fillterMe }));
        dispatch(action.getAllLeaveDoc({ name: '', page: 1, size: 0 }));
        await reset(defaultValue);
        handleClose();
      } catch (error) {}
    }
  };
  const formatEventTime = (isoDate) =>
    DateTime.fromISO(isoDate).set({ hour: 12, minute: 1 }).toISODate();

  const formatEventEndTime = (isoDate) =>
    DateTime.fromISO(isoDate).set({ hour: 24, minute: 1 }).toISODate();

  // const tempCalendarEvents = generateBookingCalendarEvent(_.union(bookings?.arr, logs?.arr))

  const getEvent = _.map(work.arr, (eachWork) => {
    const events = {
      id: eachWork?._id,
      // title: `${eachWork?.time || ''} ${eachWork?.name}  `,
      title: !eachWork?.allDay
        ? // ? `${eachWork?.time || ''} : ${eachWork?.name}`
          // : `${eachWork?.time || ''} : ${eachWork?.name}`,
          `${eachWork?.time || ''} : ${eachWork?.name}${
            eachWork?.wfh ? '🏠' : ''
          }`
        : `${eachWork?.time || ''} : ${eachWork?.name}${
            eachWork?.wfh ? '🏠' : ''
          }`,
      start: formatEventTime(eachWork?.date),
      backgroundColor:
        eachWork?.laeveWork === true
          ? '#f5071b'
          : eachWork?.workAllDay === true
          ? '#4caf50'
          : eachWork?.allDay === true
          ? '#1273de'
          : eachWork?.halfDay === true
          ? '#ef6c00'
          : eachWork?.dayOff === true
          ? '#fccb00'
          : eachWork?.dayOffHalfDay === true
          ? '#7b1fa2'
          : eachWork?.wfh === true
          ? '#ab003c'
          : '#4caf50',
      borderColor: '#FFFFFF',
    };

    return events;
  });

  const leaveDocApprove = _.filter(leaveDoc?.arr, { status: 'อนุมัติ' });
  const getleave = _.map(leaveDocApprove, (eachLeave) => {
    console.log('eachLeave', eachLeave.name, typeof eachLeave.statusHalfDay);

    if (
      eachLeave?.statusHalfDay === true ||
      eachLeave?.statusHalfDay === 'true'
    ) {
      const eventHalfday = {
        id: eachLeave?._id,
        title: `${eachLeave?.leaveTypeID?.name}:${eachLeave?.name || ''} ${
          eachLeave?.surname || ''
        }`,
        start: formatEventTime(eachLeave?.dateStart),
        end: formatEventEndTime(eachLeave?.dateEnd),
        halfDay: true,
        backgroundColor: '#ef6c00',
        borderColor: '#FFFFFF',
      };
      return eventHalfday;
    } else {
      const eventAlldays = {
        id: eachLeave?._id,
        title: `${eachLeave?.leaveTypeID?.name}:${eachLeave?.name || ''} ${
          eachLeave?.surname || ''
        }`,
        start: formatEventTime(eachLeave?.dateStart),
        end: formatEventEndTime(eachLeave?.dateEnd),
        allDay: true,
        backgroundColor: '#f5071b',
        borderColor: '#FFFFFF',
      };
      return eventAlldays;
    }
  });
  const allEvent = _.union(getEvent, getleave);

  // const leaveDocApprove1 = _.filter(
  //   leaveDoc?.arr,
  //   { status: 'อนุมัติ' },
  //   { halfDay: true }
  // );
  // const getleave1 = _.map(leaveDocApprove1, (eachLeave) => {
  //   const events = {
  //     id: eachLeave?._id,
  //     title: `${eachLeave?.leaveTypeID?.name}:${eachLeave?.name || ''} ${
  //       eachLeave?.surname || ''
  //     }`,
  //     start: formatEventTime(eachLeave?.dateStart),
  //     end: formatEventEndTime(eachLeave?.dateEnd),
  //     allDay: true,
  //     backgroundColor: '#ef6c00',
  //     borderColor: '#FFFFFF',
  //   };
  //   return events;
  // });

  // console.log('getEvent', getEvent);
  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
            }}
          >
            <AddWork
              control={control}
              Controller={Controller}
              fields={fields}
              append={append}
              remove={remove}
              setValue={setValue}
              user={user}
              date={date}
              status={status}
              setStatus={setStatus}
              setDate={setDate}
              time={time}
              setTime={setTime}
            />
          </Box>
        </form>
      </Modal>
    </div>
  );
  const renderColor = () => (
    <div className="pb-4">
      <Card>
        <div className="p-4">
          <Card>
            <div className="">
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#1273de' }} />
                <h1>ลงพื้นที่ทั้งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#4caf50' }} />
                <h1>ลงพื้นที่ครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#ef6c00' }} />
                <h1>ลางานครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#fccb00' }} />
                <h1>ทำงานในวันหยุด</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#7b1fa2' }} />
                <h1>ทำงานในวันหยุดครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#f5071b' }} />
                <h1>ลางาน</h1>
              </div>
              {/* <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#ab003c' }} />
                <h1>WFH</h1>
              </div> */}

              {/* <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#EA771C' }} />
                <h1>การเงิน</h1>
              </div> */}
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );

  const renderModalJob = () => (
    <JobInfo openJob={openJob} setOpenJob={setOpenJob} job={job} />
  );
  const renderBotton = () => (
    <div className="pb-4 flex justify-end">
      {renderSwitch()}
      <Stack spacing={2} direction="row">
        <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
          เพิ่มงาน
        </Button>
      </Stack>
      {renderModal()}
      {renderModalJob()}
    </div>
  );

  const renderCalendar = () => (
    <div>
      <div className="">
        <Box sx={{}}>
          <Card>
            <div className="p-2">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                // headerToolbar={{ start: 'title' }}
                eventClassNames="cursor-pointer hover:shadow-md border-none"
                events={allEvent}
                // other events here
                eventClick={(info) => {
                  const jobs = _.find(
                    _.union(work?.arr, leaveDoc?.arr),
                    (eachWork) => eachWork?._id === info?.event?.id
                  );

                  setJob(jobs);
                  handleJobOpen();
                }}
              />
            </div>
          </Card>
        </Box>
      </div>
    </div>
  );
  if (leaveDoc.isLoading || !leaveDoc.isCompleted || work.isLoading) {
    return <Loading isLoading />;
  }
  if (!leaveDoc.isLoading || leaveDoc.isCompleted || !work.isLoading) {
    return (
      <div>
        {renderViewTitle()}
        {renderBotton()}
        {renderCalendar()}
        {renderColor()}
        {renderModalJob()}
      </div>
    );
  }
}
