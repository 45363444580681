import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as action from '../../redux/action';
import FormDashLeave from '../../components/Forms/FormDashLeave';
function DashboardLeave({ title, subtitle }) {
  const { handleSubmit, control } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = React.useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState();
  const [fetchLeaveDoc, setfetchLeaveDoc] = useState(5);
  const user = useSelector((state) => state.user);
  const leaveType = useSelector((state) => state.leaveType);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackbuttom = () => <BackButton />;

  useEffect(() => {
    dispatch(action.getAllUser({ name, page, size, fetchLeaveDoc: true }));
    dispatch(action.leaveTypeGetAll({ name, page, size }));
  }, [name, page, size, fetchLeaveDoc]);
  const renderForm = () => (
    <div>
      <FormDashLeave user={user} leaveType={leaveType} />
    </div>
  );

  return (
    <div>
      <div>
        {renderTitle()}
        {renderBackbuttom()}
      </div>
      <div>{renderForm()}</div>
    </div>
  );
}

DashboardLeave.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
DashboardLeave.defaultProps = {
  title: '',
  subtitle: '',
};

export default DashboardLeave;
