import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Truck as TruckIcon,
  Grid as GridIcon,
  Users as UsersIcon,
  Edit,
  UserPlus,
  Clock,
  Calendar,
  Package,
  BookOpen,
} from 'react-feather';
import * as actions from '../redux/action';
import { Loading } from '../components/Loading';
import accessRight from '../utils/functions/accessRight';
import { useForm, Controller } from 'react-hook-form';
import { HomeNavbar } from '../components/Navbars';
import FooterAdmin from 'components/common/Footers/FooterAdmin';
import { Button, Card, Modal, Box } from '@mui/material';
import { Celebration } from '@mui/icons-material';
import NewsModal from '../components/Modal/NewsModal';
import NewsRender from '../components/Card/NewsRender';
import Notpermission from 'components/Card/Notpermission';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Home() {
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm();
  const me = useSelector((state) => state.me);
  const setting = useSelector((state) => state.setting);
  const news = useSelector((state) => state.news);
  const module = setting?.arr;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.settingGetAll({}));
    dispatch(actions.newsGetAll({}));
    return () => {};
  }, []);

  const onDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.newsDelete(id));
      await dispatch(actions.newsGetAll({}));
    }
  };

  const onSubmitNews = async (data) => {
    const dataSubmit = { ...data, createdBy: me?.userData?._id };
    await dispatch(actions.newsCreate(dataSubmit));
    reset();
    handleClose();
    await dispatch(actions.newsGetAll({}));
  };

  if (!me?.userData) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  if (!me?.userData?.department?._id) {
    return (
      <div className="">
        <Notpermission />
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <HomeNavbar onMobileNavOpen={() => {}} />
      <div className="min-h-screen ">
        <div className="container pt-40 mr-auto ml-auto lg:flex ">
          <div className="hidden lg:visible lg:block lg:w-1/3 py-4">
            <Card sx={{ maxHeight: 800 }}>
              <div className="p-2 flex justify-between  ">
                <div className="flex">
                  <div className="font-bold text-xl  ">ข่าวสารบริษัท </div>
                  <Celebration color="success" />
                </div>
                <div>
                  <Button size="small" onClick={() => handleOpen()}>
                    เพิ่มประกาศ
                  </Button>
                  <form onSubmit={handleSubmit(onSubmitNews)}>
                    <div>
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <div>
                            <NewsModal
                              open={open}
                              setOpen={setOpen}
                              handleOpen={handleOpen}
                              handleClose={handleClose}
                              Controller={Controller}
                              control={control}
                            />
                          </div>
                          <Button
                            variant="contained"
                            onClick={handleSubmit(onSubmitNews)}
                          >
                            บันทึก
                          </Button>
                        </Box>
                      </Modal>
                    </div>
                  </form>
                </div>
              </div>
              <div className="overflow-auto overflow-y-scroll h-64">
                <NewsRender news={news?.arr} onDelete={onDelete} />
              </div>
            </Card>
          </div>
          <div className=" lg:w-2/3 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
            <div className="my-4  px-4">
              <Link to={'/work/checkIn'}>
                <div className="bg-blue-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <Clock size={50} color="white" />
                  </div>

                  <p className=" text-white mt-4">ลงเวลา</p>
                </div>
              </Link>
            </div>
            <div className="my-4  px-4">
              <Link to={'/work/leave-work'}>
                <div className="bg-red-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <Edit size={50} color="white" />
                  </div>

                  <p className=" text-white mt-4">การลา</p>
                </div>
              </Link>
            </div>

            {module?.calendarAll && (
              <div className="my-4  px-4">
                <Link to={'/work/calendar'}>
                  <div className="bg-indigo-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <Calendar size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">ตารางงาน</p>
                  </div>
                </Link>
              </div>
            )}
            {module?.calendarMe && (
              <div className="my-4  px-4">
                <Link to={'/work/calendar-me'}>
                  <div className="bg-gray-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <Calendar size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">ตารางงานของฉัน</p>
                  </div>
                </Link>
              </div>
            )}

            {accessRight(me, 'PCD') && (
              <div className="my-4  px-4">
                <Link to={'/purchase/order'}>
                  <div className="bg-purple-400  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-money-bill text-white text-4xl my-2"></i>
                    </div>
                    <p className=" text-white mt-4">แผนกจัดซื้อ</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'SLD') && (
              <div className="my-4  px-4">
                <Link to={'/sale/quotation'}>
                  <div className="bg-green-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-dollar-sign text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">แผนกขาย</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'HRD') && (
              <div className="my-4  px-4">
                <Link to={'/hr/timestamp'}>
                  <div className="bg-orange-400  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <UserPlus size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">แผนกบุคคล</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'FND') && (
              <div className="my-4  px-4">
                <Link to={'/salary/payroll'}>
                  <div className="bg-orange-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-dollar-sign text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">แผนกการเงิน</p>
                  </div>
                </Link>
              </div>
            )}
            {/* {accessRight(me, 'PM') && ( */}
            {/* <div className="my-4  px-4">
              <Link to={'/project'}>
                <div className="bg-blue-400  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <Package size={50} color="white" />
                  </div>
                  <p className=" text-white mt-4">โครงการ</p>
                </div>
              </Link>
            </div> */}
            {/* )} */}

            {accessRight(me, 'PDD') && (
              <div className="my-4  px-4">
                <Link to={'/product'}>
                  <div className="bg-pink-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <Package size={50} color="white" />
                    </div>
                    <p className=" text-white mt-4">สินค้า</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'ADM') && (
              <div className="my-4  px-4">
                <Link to={'/admin/info'}>
                  <div className="bg-yellow-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-key text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">ผู้ดูแลระบบ</p>
                  </div>
                </Link>
              </div>
            )}

            <div className="my-4  px-4">
              <Link to={'/employee/me'}>
                <div className="bg-red-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <UsersIcon size={50} color="white" />
                  </div>

                  <p className=" text-white mt-4">ข้อมูลส่วนตัว</p>
                </div>
              </Link>
            </div>

            <div className="my-4  px-4">
              <a
                href="https://drive.google.com/drive/folders/1qZ5ZY6cGLArX1B8SySRNUAtSWJRftKfe?usp=sharing"
                target="_blank"
              >
                <div className="bg-blue-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <BookOpen size={50} color="white" />
                  </div>

                  <p className=" text-white mt-4">คู่มือการใช้งาน</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <FooterAdmin />
      </div>
    </div>
  );
}
