/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// components
import Sidebar from '../components/common/Sidebar/Sidebar';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';
import Loading from 'components/Loading';
import NotAuthorized from 'components/Error/Error';
// views
import {
  Position,
  NewPostion,
  EditPosition,
  HrEmployee,
  NewEmployee,
  EditEmployee,
  HrDashboard,
  HrLeaveForm,
  HrDoc,
  Department,
  EditDepartment,
  TimeStamp,
  LeaveTypeList,
  EditLeaveTpye,
  NewLeaveType,
  HrCalendar,
  HrWorkList,
  HrEditWork,
  CreateDepartment,
  SubBusiness,
  EditSubBusiness,
} from 'views/Hr';
import DetailEmployee from 'views/Hr/DetailEmployee';
import NewGroupEmployee from 'views/Hr/NewGroupEmployee';
import Groupemployee from 'views/Hr/GroupEmployee';
import Level from '../views/Hr/Level';
import NewLevel from '../views/Hr/NewLevel';
import DashboardLeave from '../views/Hr/DashboardLeave';
import EditGroupEmployee from 'views/Hr/EditGroupEmployee';
import EditLevel from 'views/Hr/EditLevel';
import * as actions from '../redux/action';
import accessRight from '../utils/functions/accessRight';
import { HomeNavbar } from '../components/Navbars';

export default function Hr() {
  const module = 'HRD';
  const name = 'ฝ่ายบุคคล';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    dispatch(actions.settingGetAll());

    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading isLoading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-8 md:px-8">
          {/* Header */}
          {console.log('dddd', me)}
          <div className="py-4 w-full min-h-screen ">
            {accessRight(me, module) && (
              <Switch>
                <Route path="/hr/hrcalendar" exact>
                  <HrCalendar title="ตารางงานพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/sub-business" exact>
                  <SubBusiness title="สังกัด/บริษัทย่อย" subtitle={name} />
                </Route>
                <Route path="/hr/sub-business/edit/:id" exact>
                  <EditSubBusiness
                    title="แก้ไขสังกัด/บริษัทย่อย"
                    subtitle={name}
                  />
                </Route>
                <Route path="/hr/employee" exact>
                  <HrEmployee title="รายชื่อพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/newemployee" exact>
                  <NewEmployee title="สร้างพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/editemployee/:id" exact>
                  <EditEmployee title="แก้ไขพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/detailemployee/:id" exact>
                  <DetailEmployee title="รายละเอียดพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/dashboard" exact>
                  <HrDashboard title="แดชบอร์ด" subtitle={name} />
                </Route>
                <Route path="/hr/work-list" exact>
                  <HrWorkList title="งาน" subtitle={name} />
                </Route>
                <Route path="/hr/work-list/edit/:id" exact>
                  <HrEditWork title="แก้ไขงาน" subtitle={name} />
                </Route>
                <Route path="/hr/document" exact>
                  <HrDoc title="ใบลา" subtitle={name} />
                </Route>
                <Route path="/hr/leaveform/:id" exact>
                  <HrLeaveForm title="ใบลา" subtitle={name} />
                </Route>
                <Route path="/hr/department" exact>
                  <Department title="แผนก" subtitle={name} />
                </Route>
                <Route path="/hr/department/create" exact>
                  <CreateDepartment title="สร้าง" subtitle={name} />
                </Route>
                <Route path="/hr/groupemployee" exact>
                  <Groupemployee title="ฝ่าย" subtitle={name} />
                </Route>
                <Route path="/hr/groupemployee/new" exact>
                  <NewGroupEmployee title="เพิ่มฝ่าย" subtitle={name} />
                </Route>
                <Route path="/hr/groupemployee/edit/:id" exact>
                  <EditGroupEmployee title="แก้ไขฝ่าย" subtitle={name} />
                </Route>
                <Route path="/hr/position" exact>
                  <Position title="จัดการตำแหน่งงาน" subtitle={name} />
                </Route>
                <Route path="/hr/position/new" exact>
                  <NewPostion title="เพิ่มตำแหน่ง" subtitle={name} />
                </Route>
                <Route path="/hr/position/edit/:id" exact>
                  <EditPosition title="แก้ไขตำแหน่ง" subtitle={name} />
                </Route>
                <Route path="/hr/level" exact>
                  <Level title="จัดการระดับพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/level/new" exact>
                  <NewLevel title="เพิ่มระดับพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/dashboardleave " exact>
                  <DashboardLeave />
                </Route>
                <Route path="/hr/level/edit/:id" exact>
                  <EditLevel title="แก้ไขระดับพนักงาน" subtitle={name} />
                </Route>
                <Route path="/hr/dashboardleave" exact>
                  <DashboardLeave title="ภาพรวมการลา" subtitle={name} />
                </Route>

                <Route path="/hr/timestamp" exact>
                  <TimeStamp title="รายการลงเวลางาน" subtitle={name} />
                </Route>
                <Route
                  path="/hr/department/edit/:id"
                  exact
                  component={EditDepartment}
                >
                  <EditDepartment title="แก้ไขแผนก" subtitle={name} />
                </Route>
                <Route path="/hr/setting/leave-type" exact>
                  <LeaveTypeList title="ตั้งค่าชนิดการลา" subtitle={name} />
                </Route>
                <Route path="/hr/setting/leave-type/new" exact>
                  <NewLeaveType title="เพิ่มชนิดการลา" subtitle={name} />
                </Route>
                <Route path="/hr/setting/leave-type/edit/:id" exact>
                  <EditLeaveTpye title="แก้ไขชนิดการลา" subtitle={name} />
                </Route>
                <Redirect from="/." to="/." />
              </Switch>
            )}
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
