import {
  CUSTOMER_ALL,
  CUSTOMER_DELETE,
  CUSTOMER_GET,
  CUSTOMER_LOADING,
  CUSTOMER_ERROR,
} from '../type';
import axios from '../../config/axios';

export function getAllCustomer(params = {}) {
  return async function (dispatch) {
    try {
      const { name = '', page = 1, size = '' } = params;
      const { data, status, statusuni } = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer?name=${name}&page=${page}&size=${size}`
      );

      if (status === 200) {
        dispatch({ type: CUSTOMER_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: CUSTOMER_ERROR });
    }
  };
}
export function getOneCustomer(id) {
  return async function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/customer/${id}`)
      .then((response) => {
        dispatch({ type: CUSTOMER_GET, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function deleteCustomer(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: CUSTOMER_LOADING });
      const { data } = axios.delete(
        `${process.env.REACT_APP_API_URL}/customer/${id}`
      );
    } catch (error) {
      console.error(error);
      dispatch({ type: CUSTOMER_ERROR });
    }
  };
}

export function addCustomer(
  buname,
  TaxId,
  name,
  tel,
  address,
  status,
  statusuni
) {
  return async function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/customer/`, {
        businessname: buname,
        TaxId,
        name,
        tel,
        address,
        status,
        statusuni,
      })
      .then((response) => {
        console.log('response: ', response);
        // do something about response
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function putCustomer(
  id,
  buname,
  TaxId,
  name,
  tel,
  address,
  status,
  statusuni
) {
  return async function (dispatch) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/customer/${id}`, {
        businessname: buname,
        TaxId,
        name,
        status,
        statusuni,
        tel,
        address,
      })
      .then((response) => {
        console.log('response: ', response);
        // do something about response
      })
      .catch((err) => {
        console.error(err);
      });
    console.log(id);
  };
}
