import axios from 'axios';
import api from '../../utils/functions/api';
import {
  LEAVEDOC_ALL,
  LEAVEDOC_DELETE,
  LEAVEDOC_GET,
  LEAVEDOC_LOADING,
  LEAVEDOC_PUT,
  LEAVEDOC_ERROR,
  LEAVEDOC_POST,
} from '../type';

export const resetLeaveDoc = () => (dispatch) => {
  dispatch({ type: LEAVEDOC_ALL, payload: [] });
};

export const getAllLeaveDoc = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      me = '',
      approve = '',
      user = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/leavedoc?name=${name}&size=${size}&page=${page}&me=${me}&approve=${approve}&user=${user}`
    );

    if (status === 200) {
      dispatch({ type: LEAVEDOC_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVEDOC_ERROR });
  }
};
export const getOneLeaveDoc = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/leavedoc/${id}`
    );
    if (status === 200) {
      dispatch({ type: LEAVEDOC_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVEDOC_ERROR });
  }
};

export const deleteLeaveDoc = (id) => async (dispatch) => {
  try {
    dispatch({ type: LEAVEDOC_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/leavedoc/${id}`
    );
    dispatch({ type: LEAVEDOC_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVEDOC_ERROR });
  }
};

export const addLeaveDoc = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LEAVEDOC_LOADING });

    console.log('Add Leave Doc');

    if (payload.file) {
      console.log('On File Upload');
      // Upload File First
      const formData = new FormData();
      formData.append('file', payload.file);
      const { data: uploadData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      // console.log("Upload Data", uploadData);
      await axios.post(`${process.env.REACT_APP_API_URL}/leavedoc`, {
        ...payload,
        file: uploadData?.id,
      });
      dispatch({ type: LEAVEDOC_POST, payload: data });
    }
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/leavedoc`,
      {
        ...payload,
        // eslint-disable-next-line comma-dangle
      }
    );
    dispatch({ type: LEAVEDOC_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVEDOC_ERROR });
  }
};

export const putLeaveDoc = (id, payload) => async (dispatch) => {
  // return async function (dispatch) {
  //   const payloadData = payload;

  //   if (payloadData.file) {
  //     console.log('On File Upload');
  //     // Upload File First
  //     const formData = new FormData();
  //     formData.append('file', payloadData.file);
  //     const { data: uploadData } = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/upload/file`,
  //       formData,
  //       {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       }
  //     );

  //     // console.log("Upload Data", uploadData);
  //     const { data } = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/leavedoc/${id}`,
  //       {
  //         ...data,
  //         file: uploadData?.id,
  //       }
  //     );

  //     dispatch({ type: LEAVEDOC_PUT, payloadData: data });
  //   }
  //   axios
  //     .put(`${process.env.REACT_APP_API_URL}/leavedoc/${id}`, payloadData)
  //     .then((response) => {
  //       // console.log("response: ", response);
  //       dispatch({ type: LEAVEDOC_PUT });
  //       // do something about response
  //     })
  //     .catch((err) => {});
  // };
  try {
    dispatch({ type: LEAVEDOC_LOADING });
    const payloadData = payload;
    if (payloadData.file) {
      const formData = new FormData();
      formData.append('file', payloadData.file);
      const { data: uploadData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      // console.log("Upload Data", uploadData);
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/leavedoc/${id}`,
        {
          ...data,
          file: uploadData?.id,
        }
      );
      dispatch({ type: LEAVEDOC_PUT, payload: data });
    } else {
      const { data } = await api.put(
        `${process.env.REACT_APP_API_URL}/leavedoc/${id}`,
        payload
      );
      console.log('DATA', data);
      dispatch({ type: LEAVEDOC_PUT, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVEDOC_ERROR });
  }
};
