/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import { BackButton } from 'components/Button';
import MonthPicker from 'components/Datepicker/MonthPicker';
import * as actions from '../../../redux/action';
import { ViewTitle } from '../../../components/ViewTitle';

function NewSalary({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  console.log('usr', user);

  const userFilter = _.filter(
    user?.arr,
    (eachUser) => eachUser.department.name !== 'ADMIN'
  );
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(50);
  const [taxSSO, setTaxSSO] = React.useState(5);
  const [date, setDate] = React.useState(new Date());
  const [datePay, setDatePay] = React.useState(new Date());
  const { handleSubmit, control, setValue } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'salary',
  });

  useEffect(() => {
    dispatch(actions.getAllUser({ findName, page, size }));
    return () => {};
  }, [findName, page, size]);

  useEffect(() => {
    if (!user.isloading) {
      setValue(
        'salary',
        _.map(userFilter, (eachUser) => {
          // console.log("user in set value", eachUser);
          const users = {
            ...eachUser,
            ot: 0,
            extra: 0,
            extra2: 0,
            tax: 0,
            etc: 0,
            allowance: 0,
            emExtra: 0,
            emExtra2: 0,
          };
          return users;
        })
      );
    }

    return () => {};
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangeTax = (event) => {
    setTaxSSO(event.target.value);
  };
  const calculateSSO = (salary) => {
    const SSO = (salary * taxSSO) / 100;
    if (salary > 15000) {
      const SSO = (15000 * taxSSO) / 100;
      return SSO;
    }
    return SSO;
  };

  const renderBackButton = () => <BackButton />;
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const salary = () => (
    <Paper>
      <form>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
            <TableHead className="bg-blue-600 ">
              <TableRow>
                <TableCell sx={{ width: '5%' }}>
                  <h1 className="text-white font-bold text-center">ลำดับ</h1>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>
                  <h1 className="text-white font-bold text-center">
                    ชื่อ-สกุล
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold">เงินเดือน</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    ค่าแรงวันหยุด
                  </h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    เบี้ยเลี้ยง
                  </h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    เงินเพิ่มพิเศษ
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }} className="bg-red-700">
                  <h1 className="text-white font-bold text-center">
                    ประกันสังคม
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }} className="bg-red-700">
                  <h1 className="text-white font-bold text-center">อื่นๆ</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }} className="bg-green-700">
                  <h1 className="text-white font-bold text-center">เงินสมทบ</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }} className="bg-green-700">
                  <h1 className="text-white font-bold text-center">เงินสมทบ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white font-bold text-center">
                    ดำเนินการ
                  </h1>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log('fields', fields)}
              {fields?.map((User, index) => (
                <TableRow key={User.index}>
                  <TableCell>
                    <h1 className="text-center">{index + 1}</h1>
                  </TableCell>{' '}
                  <TableCell sx={{ width: '15%' }}>
                    <h1>
                      {User?.name} {User?.surname}
                    </h1>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <h1>
                      {User?.salary
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </h1>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].ot`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].allowance`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].extra`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <h1 className="text-center">
                      {calculateSSO(User?.salary)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </h1>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].etc`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].emExtra`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <div>
                      <Controller
                        name={`salary[${index}].emExtra2`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => remove(index)}>ลบ</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>{' '}
          </Table>
        </TableContainer>{' '}
      </form>
      <TablePagination
        rowsPerPageOptions={[10, 30, 40, 60, 100]}
        component="div"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={user?.total || 0}
        rowsPerPage={size}
        page={page - 1}
      />
    </Paper>
  );

  const onSubmitSalary = async (data) => {
    try {
      const payload = {
        arr: _.map(data.salary, (eachSalary) => ({
          ...eachSalary,
          _id: undefined,
          user: eachSalary?._id,
          taxSSO,
          sumSSO: calculateSSO(eachSalary?.salary),
          sumEtc: parseInt(eachSalary?.etc),
          sumSalary:
            parseInt(eachSalary?.salary) +
            parseInt(eachSalary?.extra) +
            parseInt(eachSalary?.allowance) +
            parseInt(eachSalary?.ot),
          date: dayjs(date).startOf('month').format(),
          datePay,
          file: undefined,
        })),
      };
      await dispatch(actions.addSalary(payload));
      history.goBack();
    } catch (error) {
      console.error('Error on Create Salary', error);
    }
  };

  if (user.isloading) {
    return <>error</>;
  }
  if (!user.isloading) {
    return (
      <div className="min-h-screen">
        <div className="pb-2">
          {renderTitle()}
          {renderBackButton()}
        </div>
        <Card>
          <CardContent>
            <div>
              <div className="flex">
                <div className="p-2 w-full">
                  <Card>
                    <CardContent>
                      {' '}
                      <form onSubmit={handleSubmit(onSubmitSalary)}>
                        <div className="flex justify-between">
                          <div>
                            <div className="flex gap-2">
                              <div>
                                <MonthPicker
                                  value={date}
                                  setValue={setDate}
                                  label="เดือน"
                                />
                              </div>{' '}
                              <div>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    label="วันที่จ่ายเงิน"
                                    value={datePay}
                                    onChange={(newValue) => {
                                      setDatePay(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                </LocalizationProvider>{' '}
                              </div>
                            </div>
                            <div className="pt-4">
                              <h1>เงินสมประกันสังคม</h1>

                              <TextField
                                size="small"
                                value={taxSSO}
                                onChange={handleChangeTax}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>{' '}
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              type="submit"
                              // onClick={onSubmitQuotation}
                            >
                              บันทึก
                            </Button>
                          </div>
                        </div>
                      </form>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2">{salary()}</div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
  return <>error</>;
}
export default NewSalary;
