import React from 'react';
import {
  Card,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Paper,
} from '@mui/material';
import _ from 'lodash';

export default function FormDashLeave({ user, leaveType }) {
  console.log('User in form', user);
  console.log('LeaveType  in form', leaveType?.arr);

  return (
    <div className="py-4  p-10">
      <Card>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead style={{ backgroundColor: '#3f51b5', color: '#ffffff' }}>
              <TableRow>
                <TableCell
                  style={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    border: '1px solid #ffffff',
                    textAlign: 'center',
                  }}
                >
                  พนักงาน
                </TableCell>
                {leaveType?.arr?.map((eachLeaveType) => (
                  <TableCell
                    style={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                      border: '1px solid #ffffff',
                      textAlign: 'center',
                    }}
                  >
                    {eachLeaveType?.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.arr?.map((eachUser, index) => (
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    <div className="font-bold   ">
                      {index + 1}. {eachUser.name} {eachUser?.surname}
                    </div>
                  </TableCell>
                  {leaveType?.arr?.map((eachLeaveType, index) => {
                    console.log('eachUser', eachUser);

                    const quota = _.find(
                      eachUser?.allLeaveDoc,
                      (eachUserLeaveDoc) =>
                        eachLeaveType?._id === eachUserLeaveDoc?._id?._id
                    );
                    console.log('Quota  in', quota);

                    return (
                      <TableCell style={{ textAlign: 'center' }}>
                        {quota?.sum || 0} /{eachLeaveType?.quantity} วัน
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
}
