import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import ProductServe from 'components/Forms/FormProductArray';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as actions from '../../../redux/action';
import SumAllPrice from '../../../components/Card/SumAllPrice';
import SumDiscount from 'components/Card/SumDiscount';
import Sumprice from 'components/Card/Sumprice';

function EditIn({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const invoice = useSelector((state) => state.invoice);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const { handleSubmit, control, setValue, watch } = useForm({});

  const [date, setDate] = React.useState();
  const [dueDate, setDueDate] = React.useState();
  const [productVat, setProductVat] = useState(false);
  const [productVatUni, setProductVatUni] = useState(false);
  const [basicVat, setBasicVat] = React.useState(false);

  const [productList, setProductList] = useState([]);
  const [customerData, setCustomerData] = useState();

  useEffect(() => {
    dispatch(actions.getOneInvoice(params.id));
    dispatch(actions.getAllProduct());

    return () => {};
  }, []);
  useEffect(() => {
    setDate(invoice?.date);
    setDueDate(invoice?.dueDate);
    setValue('productArray', invoice?.product);
    setValue('businessname', invoice?.customer?.businessname);
    setValue('taxId', invoice?.customer?.TaxId);
    setValue('status', invoice?.customer?.status);
    setValue('tel', invoice?.customer?.tel);
    setValue('name', invoice?.customer?.name);
    setValue('address', invoice?.customer?.address);
    setValue('payment', invoice?.payment);
    setValue('credit', invoice?.credit);
    setValue('note', invoice?.note);
    setValue('running_number', invoice?.running_number);
    setProductVatUni(invoice?.statusTaxUni);
    setProductVat(invoice?.statusTax);

    return () => {};
  }, [invoice]);
  console.log('customer', invoice);

  const onSubmitQuotation = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          date,
          dueDate,
          ...data,
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: sumAllPrice(),
          statusTaxUni: productVatUni,
          statusTax: productVat,
        };
        // console.log("dataSubmit", ProcessData);
        await dispatch(actions.putInvoice(params.id, ProcessData));
        await dispatch(actions.getAllInvoice({ name: '', page: 1, size: 5 }));
        history.push('/sale/invoice');
      } catch (error) {}
    }
  };
  const tax = (status) => {
    if (!status) {
      return 0;
    }
    const price = SumAllPrice(productList);
    const taxAll = price * 0.07;
    return taxAll;
  };
  console.log('Tax Invoice', productVat);

  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };
  const priceTax = (status) => SumAllPrice(productList) + tax(status);
  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  const priceTaxUni = () =>
    SumAllPrice(productList) - taxUniversity() + taxBasicVat();

  const sumPrice = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => each?.price * each?.quantity);
    return sumP;
  };
  const sumDiscount = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
    return sumP;
  };

  const sumAllPrice = (productList) => {
    if (productList?.length === 0) {
      return 0;
    }
    return sumPrice() - sumDiscount();
  };
  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return Sumprice(productList) - SumDiscount(productList);
  };
  const taxPrice = () => {
    if (!productVat) {
      return 0;
    } else {
      return Price() * 0.03;
    }
  };
  const taxBasicVat = () => {
    return Price() * 0.07;
  };
  const allPrice = () => {
    return Price() + taxBasicVat() - taxPrice();
  };

  const allPriceNoStatus = () => {
    return Price() + taxBasicVat();
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  if (!invoice.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <div>
          <div>
            <div className="flex">
              <Card>
                <div className="p-2">
                  <h1 className="pb-4"> ข้อมูลลูกค้า</h1>
                  <div className="flex w-full">
                    <Controller
                      name="businessname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          label="ชื่อลูกค้า"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </div>{' '}
                  {/* <div className="pl-4 pt-4">
                      <Controller
                        name="status"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox {...field} checked={field.value} />
                              }
                              label="นิติบุคคล"
                            />
                          </div>
                        )}
                      />
                    </div> */}
                  <Controller
                    name="taxId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="หมายเลขผู้เสียภาษี"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="ที่อยู่"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                        size="small"
                      />
                    )}
                  />
                </div>
              </Card>

              <div className="p-2 w-full">
                <Card>
                  <CardContent>
                    {' '}
                    <form onSubmit={handleSubmit(onSubmitQuotation)}>
                      <div className="flex justify-between">
                        <div className="flex">
                          <h1 className="text-xl pt-8 pr-2">
                            {invoice?.prefix}
                          </h1>
                          <div>
                            <Controller
                              name="running_number"
                              control={control}
                              defaultValue="-"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={field.value}
                                  label="เลขที่เอกสาร"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  size="small"
                                />
                              )}
                            />
                          </div>
                        </div>

                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={onSubmitQuotation}
                        >
                          บันทึก
                        </Button>
                      </div>
                      <div className="flex">
                        <div className="py-1">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="วันที่"
                              value={date}
                              size="small"
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>{' '}
                        </div>
                        <div className="py-1">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="วันที่ครบ"
                              value={dueDate}
                              size="small"
                              onChange={(newValue) => {
                                setDueDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>{' '}
                        </div>
                      </div>
                      <div className="py-1">
                        <Controller
                          name="credit"
                          control={control}
                          defaultValue="-"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value}
                              label="จำนวนวันเครดิต"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="py-1">
                        <Controller
                          name="payment"
                          control={control}
                          defaultValue="โอน/เงินสด"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value}
                              label="วิธีการชำระเงิน"
                              variant="outlined"
                              fullWidth
                              size="small"
                            />
                          )}
                        />{' '}
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2">
              {' '}
              <ProductServe
                setProductList={setProductList}
                setValue={setValue}
                productVatUni={productVatUni}
                setProductVatUni={setProductVatUni}
                control={control}
                watch={watch}
                statusPickUp={false}
                tatusPickUp={true}
                productVat={productVat}
                setProductVat={setProductVat}
                setBasicVat={setBasicVat}
                basicVat={basicVat}
              />
            </div>
            <Card>
              <CardContent>
                <div className="flex w-full ">
                  <div className="w-1/2">
                    {' '}
                    <Controller
                      name="note"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="หมายเหตุ"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>

                  <div className="flex justify-between  w-1/2 ml-2  ">
                    <div>
                      <div>รวมเงิน </div>
                      <div>ส่วนลดการค้า</div>
                      <div>เงินหลังหักส่วนลด</div>
                      <div>ภาษีมูลค่าเพิ่ม 7%</div>
                      {productVatUni === true
                        ? 'หัก ณ ที่จ่าย 1%'
                        : productVat === true
                        ? 'หัก ณ ที่จ่าย 3%'
                        : ' '}

                      <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                    </div>
                    <div>
                      {productVatUni === true ? (
                        <div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {Price()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {taxBasicVat()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            <div>
                              {taxUniversity()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>
                          <div className="font-bold ">
                            {priceTaxUni()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      ) : productVat === true ? (
                        <div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {Price()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {taxBasicVat()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            <div>
                              {taxPrice()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>
                          <div className="font-bold ">
                            {allPrice()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {Price()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {taxBasicVat()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div className="font-bold ">
                            {allPriceNoStatus()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default EditIn;
